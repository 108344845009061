import React, { useContext, useEffect, useReducer } from 'react';

import { settings } from 'src/config';
import { Flash, User } from 'src/types';

import Types from './types';
import {
  Action,
  setSession,
  setLoading,
  setLocale,
  getStorage,
  setStorage,
} from 'src/store';

import * as langs from 'src/languages';

/**
 * @see https://www.w3schools.com/tags/ref_language_codes.asp
 */
interface State {
  locale: string;
  messages: { [key: string]: string };
  languages: string[];
  loading: boolean;
  currentUser: User;
  flash: Flash[];
  sidebar: {
    open: boolean;
    title: string;
    // ideaId: string | null;
  };
  search: string | null;
  // executeEditIdea: boolean;
  category: string | 'ALL';
}

const browserLanguage = window.navigator.language.split('-')[0];
const settingLanguage = getStorage('Language') || browserLanguage;
const initialState = {
  locale: settingLanguage,
  messages: langs[settingLanguage],
  languages: Object.keys(langs).sort(),
  loading: true,
  currentUser: {
    id: '',
    name: '',
    displayName: '',
    avatars: null,
    locale: '',
  },
  flash: [],
  sidebar: {
    open: false,
    title: '',
    // ideaId: null,
  },
  search: null,
  category: 'ALL',
  // executeEditIdea: false,
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case Types.SET_LOCALE:
      return {
        ...state,
        locale: setStorage('Language', action.locale, { days: 7 }),
        messages: langs[action.locale],
      };

    case Types.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case Types.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };

    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case Types.SET_SESSION:
      if (action.currentUser) {
        return {
          ...state,
          currentUser: action.currentUser,
        };
      } else {
        return state;
      }

    case Types.SET_FLASH:
      return {
        ...state,
        flash: action.flash,
      };

    case Types.OPEN_SIDEBAR:
      return {
        ...state,
        sidebar: {
          open: true,
          title: action.title,
          // ideaId: action.ideaId,
        },
      };

    case Types.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebar: {
          open: false,
          title: '',
          // ideaId: null,
        },
      };

    case Types.SET_EXECUTE_EDIT_IDEA:
      return {
        ...state,
        // executeEditIdea: action.executeEditIdea,
      };

    default:
      return state;
  }
};

// context

interface Context {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const StoreContext = React.createContext<Context>({
  state: initialState,
  dispatch: () => {},
});

export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const url = `${settings.baseURL}/api/auth/client_checkin`;
    fetch(url, {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(setLocale(result.locale));
        dispatch(setSession(result));
        dispatch(setLoading(false));
      });
  }, []);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useDispatch = () => {
  const { dispatch } = useContext(StoreContext);
  return dispatch;
};

export const useSelector = (selector) => {
  const { state } = useContext(StoreContext);
  return selector(state);
};

export const useStore = () => useContext(StoreContext);

export * from './actions';
export * from './use_local_storage';
