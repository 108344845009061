export default {
  SET_LOCALE: 'SET_LOCALE',
  SET_LOADING: 'SET_LOADING',
  SET_SESSION: 'SET_SESSION',
  SET_FLASH: 'SET_FLASH',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  SET_SEARCH: 'SET_SEARCH',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_EXECUTE_EDIT_IDEA: 'SET_EXECUTE_EDIT_IDEA',
} as const;
