import React from 'react';
import RingLoader from 'react-spinners/RingLoader';
import { css } from '@emotion/core';

// vertical-horizontal center
const override = css`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: fadeIn 2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const AppLoading = (props) => {
  return <RingLoader css={override} color={'#1117A3'} />;
};

export default AppLoading;
