import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { settings } from 'src/config';
import AppLoading from 'src/AppLoading';
import { StoreProvider } from 'src/store';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorker from 'src/serviceWorker';

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(`${settings.muiLicenseKey}`);

// Locale should not affect the tests. We ensure this by providing
// messages with the key as the value of each message.

// Use React.lazy to load the App main code so that we can diaplay
// the loading screen as soon as possible.
const App = lazy(() => import('src/App'));
const Preview = lazy(() => import('src/Preview'));
// const MobileApp = lazy(() => import('src/mobile/App'));
// const MobilePreview = lazy(() => import('src/mobile/Preview'));

// function isSmartPhone() {
//   const regexp = /Android|iPhone/i;
//   return window.navigator.userAgent.search(regexp) !== -1;
// }

const Dispatch = () => {
  // Amplify Console Preview
  if (settings.preview) {
    return (
      <Suspense fallback={<AppLoading />}>
        <Preview />
      </Suspense>
    );
  }

  // Initialize React Router at the beginning because
  // StoreProvider uses the current location.

  // Initialize StoreProvider before loading the App
  // so that we can initiate a session while loading the App.

  return (
    <BrowserRouter>
      <StoreProvider>
        <ErrorBoundary>
          <Suspense fallback={<AppLoading />}>
            <App />
          </Suspense>
        </ErrorBoundary>
      </StoreProvider>
    </BrowserRouter>
  );
};

// Use Sentry for exception logging.  This must be done before calling ReactDOM.render.
// https://docs.sentry.io/platforms/javascript/react/
if (settings.sentry) {
  Sentry.init(settings.sentry);
}

ReactDOM.render(<Dispatch />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
