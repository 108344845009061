import { useState } from 'react';
import { add, isAfter, Duration } from 'date-fns';

export const getStorage = (key) => {
  try {
    const json = window.localStorage.getItem(key);
    const item = JSON.parse(json || 'null');

    if (!item || isAfter(new Date(), new Date(item.expires))) {
      return null;
    }

    return item.value;
  } catch (e) {
    // console.error(e.stack);
    return null;
  }
};

/**
 * @see https://date-fns.org/v2.22.1/docs/Duration
 *
 * @param key
 * @param value
 * @param duration Duration
 */
export const setStorage = <T>(key: string, value: T, duration: Duration): T => {
  const expires = add(new Date(), duration).toISOString();

  try {
    window.localStorage.setItem(key, JSON.stringify({ value, expires }));
  } catch (e) {
    console.error(e);
  }

  return value;
};

export const useLocalStorage = <T = any>(
  key,
  initialValue: T | undefined
): [T, (value: T, expires: Duration) => void, () => void, () => void] => {
  const [state, setState] = useState<T>(getStorage(key) || initialValue);
  const setStateAndStorage = (value: T, expires: Duration) => {
    const valueToStore = value instanceof Function ? value(state) : value;
    return setState(setStorage(key, valueToStore, expires));
  };
  const deleteStorage = () => {
    window.localStorage.removeItem(key);
  };
  const refetch = () => {
    setState(getStorage(key) || initialValue);
  };

  return [state, setStateAndStorage, deleteStorage, refetch];
};
