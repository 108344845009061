import { settings as testing } from './testing';
import { settings as staging } from './staging';
import { settings as production } from './production';
import { settings as development } from './development';

const environments = { testing, staging, production, development };

/**
 * Obsolete
 * Use dotenv to switch settings
 * Environment-specific build is done with env-cmd
const environments = {
  development: {
    baseURL: `http://${window.location.hostname}:5000`,
    preview: process.env.REACT_APP_PREVIEW,
    userPoolId: 'ap-northeast-1_Wt2xOtm0Q',
    userPoolWebClientId: '4np54rlji9c6d6meuh5jpl3ij6',
    nativeLogin: true
  },
  production: {
    baseURL: process.env.REACT_APP_BASE_URL || '',
    preview: window.location.hostname.endsWith('amplifyapp.com'),
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    nativeLogin: process.env.REACT_APP_NATIVE_LOGIN || false,
    sentry: process.env.REACT_APP_SENTRY_DSN ? {
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_SENTRY_RELEASE,
    } : null,
  },
};
 */

const locale = 'js';
export const settings = Object.assign(
  {
    baseURL: process.env.REACT_APP_BASE_URL || '',
    baseSocket: process.env.REACT_APP_BASE_SOCKET || '',
    preview: window.location.hostname.endsWith('amplifyapp.com'),
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    nativeLogin: process.env.REACT_APP_NATIVE_LOGIN || false,
    sentry: process.env.REACT_APP_SENTRY_DSN
      ? {
          dsn: process.env.REACT_APP_SENTRY_DSN,
          environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
          release: process.env.REACT_APP_SENTRY_RELEASE,
        }
      : null,
    muiLicenseKey: process.env.REACT_APP_MUI_LICENSE_KEY,
  },
  environments[process.env.REACT_APP_ENVIRONMENT]
);

export const config = { locale };
