import React, { ErrorInfo } from 'react';

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    console.log('getDerivedStatefromError');
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log('this.state.hasError', this.state.hasError);
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
