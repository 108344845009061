import Types from './types';

export const setLocale = (locale) => ({
  type: Types.SET_LOCALE,
  locale,
});

export const setFlash = (flash) => ({
  type: Types.SET_FLASH,
  flash: flash,
});

export const setSearch = (search) => ({
  type: Types.SET_SEARCH,
  search: search,
});

export const setSession = ({ currentUser }) => ({
  type: Types.SET_SESSION,
  currentUser: currentUser,
});

export const setLoading = (flag: boolean) => ({
  type: Types.SET_LOADING,
  loading: flag,
});

export const setCategory = (category) => ({
  type: Types.SET_CATEGORY,
  category: category,
});

export const setExecuteEditIdea = (flag: boolean) => ({
  type: Types.SET_EXECUTE_EDIT_IDEA,
  // executeEditIdea: flag,
});

export const openSideBar = ({ title, ideaId }) => ({
  type: Types.OPEN_SIDEBAR,
  title: title,
  // ideaId: ideaId,
});

export const closeSideBar = () => ({
  type: Types.CLOSE_SIDEBAR,
});

export type Action =
  | ReturnType<typeof setLocale>
  | ReturnType<typeof setFlash>
  | ReturnType<typeof setSearch>
  | ReturnType<typeof setSession>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setCategory>
  | ReturnType<typeof openSideBar>
  | ReturnType<typeof closeSideBar>
  | ReturnType<typeof setExecuteEditIdea>;
